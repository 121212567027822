import axiosApiInstance from '../axios/axiosApiInstance';
import { StoreConfigsResponseDto } from '../types/store-configs-response-dto';
import { StoreResponseDto } from '../types/store-response-dto';

export const fetch = async (): Promise<StoreResponseDto> => {
  const response = await axiosApiInstance.get('/stores');

  return response?.data;
};

export const fetchConfigs = async (): Promise<StoreConfigsResponseDto> => {
  const response = await axiosApiInstance.get(`stores/configs`);

  return response.data;
};
