import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Button, Icon, Link, Popover, Text } from '@nimbus-ds/components';
import { PlusCircleIcon } from '@nimbus-ds/icons';

import { AMP_BUTTON, sendAmplitudeData } from 'commons/utils/amplitude';
import { BREAKPOINTS, SIDEMODALS } from 'commons/utils/constants';
import { useDashboardContext } from 'commons/utils/hooks/useDashboardContext';
import { useSidemodalsContext } from 'commons/utils/hooks/useModal';
import useOnboarding from 'commons/utils/hooks/useOnboarding';
import { useStoreContext } from 'commons/utils/hooks/useStoreContext';
import useWindowDimensions from 'commons/utils/hooks/useWindowDimensions';

const OnboardingAddCredit = () => {
  const store = useStoreContext();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { state } = useDashboardContext();
  const { dispatch: dispatchSidemodal } = useSidemodalsContext();
  const { toggleOrAddOnboarding } = useOnboarding();

  const handleGoToStep = (shouldAction: boolean) => {
    let amplitudeAction = 'Onboarding - highlight 1 - Next';
    const step = {
      step: 2,
      visible: true,
      storeId: store.externalStoreId
    };

    toggleOrAddOnboarding(store.externalStoreId, step);

    if (shouldAction) {
      amplitudeAction = 'Onboarding - highlight 1 - Go to Action';
      dispatchSidemodal({
        type: 'OPEN_MODAL',
        payload: SIDEMODALS.ADDCREDIT
      });
    }

    sendAmplitudeData(AMP_BUTTON, {
      action: amplitudeAction
    });
  };

  const onboarding = useMemo(() => {
    return state?.onboarding?.filter(
      (onboarding) => onboarding.storeId === store.externalStoreId
    )[0];
  }, [state?.onboarding, store.externalStoreId]);

  return (
    <Popover
      content={
        <Box display="flex" flexDirection="column" width="280" gap="2">
          <Text fontWeight="bold" color="neutral-background">
            {t('widgets.onboarding.step-1.title')}
          </Text>
          <Text color="neutral-background">
            {t('widgets.onboarding.step-1.text')}
          </Text>
          <Box ml="auto">
            <Link
              as="button"
              onClick={() => handleGoToStep(false)}
              appearance="neutral-background"
            >
              {t('widgets.onboarding.step-1.button')}
            </Link>
          </Box>
        </Box>
      }
      visible={
        onboarding?.visible &&
        onboarding.step === 1 &&
        !store.configuration.hasCreatedDispatch
      }
      backgroundColor="primary-interactiveHover"
      position={width > BREAKPOINTS.md ? 'left-start' : 'bottom-start'}
      width="280px"
    >
      <Button appearance="primary" onClick={() => handleGoToStep(true)}>
        <Icon source={<PlusCircleIcon color="white" />} />
        {t('widgets.balance.buttons.add-credit')}
      </Button>
    </Popover>
  );
};

export default OnboardingAddCredit;
