import { ErrorType } from 'commons/utils/errors/error-type';
import { Order } from 'commons/utils/types/dashboard-response-dto';

type DashboardError = {
  type: ErrorType | null;
  orders?: Order[];
  values?: {
    [k: string]: string | number | undefined;
  };
};

type Onboarding = {
  step: number;
  visible: boolean;
  storeId: string;
};

export type DashboardState = {
  currentTab: 'shipments' | 'external';
  orders: Order[];
  onboarding?: Onboarding[] | [];
  error?: DashboardError | undefined;
};

export type Action =
  | {
      type: 'CHANGE_TAB';
      payload: 'shipments' | 'external';
    }
  | {
      type: 'DEFINE_ORDERS';
      payload: Order[];
    }
  | {
      type: 'DEFINE_ONBOARDING';
      payload: Onboarding[];
    }
  | {
      type: 'SET_ERROR';
      payload: DashboardError;
    }
  | {
      type: 'CLEAR_ERROR';
    };

const getInitialState = () => {
  const onboarding = localStorage.getItem('onboarding')
    ? JSON.parse(localStorage.getItem('onboarding') as string)
    : null;

  if (!Array.isArray(onboarding) && onboarding) {
    localStorage.setItem('onboarding', JSON.stringify([onboarding]));
  }

  return onboarding;
};

const initialState: DashboardState = {
  currentTab: 'shipments',
  orders: [],
  onboarding: getInitialState(),
  error: {
    type: null
  }
};

const dashboardReducer = (
  state: DashboardState,
  action: Action
): DashboardState => {
  switch (action.type) {
    case 'CHANGE_TAB':
      return { ...state, currentTab: action.payload };

    case 'DEFINE_ORDERS':
      return { ...state, orders: action.payload };

    case 'DEFINE_ONBOARDING':
      return { ...state, onboarding: action.payload };

    case 'SET_ERROR':
      return { ...state, error: action.payload };

    case 'CLEAR_ERROR':
      return { ...state, error: undefined };

    default:
      return state;
  }
};

export { initialState, dashboardReducer };
