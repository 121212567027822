import { ELocale, DomainTranslations } from 'App/i18n/i18n.types';

import esAR from './common.es-AR.json';
import esMX from './common.es-MX.json';
import ptBR from './common.pt-BR.json';

const commonTranslations: DomainTranslations = {
  name: 'common',
  languages: {
    [ELocale.ARGENTINA]: esAR,
    [ELocale.MEXICO]: esMX,
    [ELocale.BRAZIL]: ptBR
  }
};

export default commonTranslations;
