import { ELocale, DomainTranslations } from 'App/i18n/i18n.types';

import Domain from 'commons/utils/domains';

import esAR from './billing.es-AR.json';
import esMX from './billing.es-MX.json';
import ptBR from './billing.pt-BR.json';

const billingTranslations: DomainTranslations = {
  name: Domain.BILLING,
  languages: {
    [ELocale.ARGENTINA]: esAR,
    [ELocale.MEXICO]: esMX,
    [ELocale.BRAZIL]: ptBR
  }
};

export default billingTranslations;
