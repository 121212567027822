import { Outlet } from 'react-router-dom';

import { PreferencesProvider } from 'commons/utils/hooks/usePreferencesContext';

const PreferencesContextProvider = () => {
  return (
    <PreferencesProvider>
      <Outlet />
    </PreferencesProvider>
  );
};

export default PreferencesContextProvider;
