import { useCallback } from 'react';

import { useDashboardContext } from './useDashboardContext';

type Onboarding = {
  step: number;
  visible: boolean;
  storeId: string;
};

const useOnboarding = () => {
  const { state, dispatch } = useDashboardContext();

  const toggleOrAddOnboarding = useCallback(
    (storeId: string, currentStep: Onboarding) => {
      const onboardingList = state.onboarding || [];

      const storeExists = onboardingList.some(
        (onboarding) => onboarding.storeId === storeId
      );

      let updatedOnboardingList;

      if (storeExists) {
        updatedOnboardingList = onboardingList.map((onboarding) => {
          if (onboarding.storeId === storeId) {
            return {
              storeId: storeId,
              visible: currentStep.visible,
              step: currentStep.step
            };
          } else {
            return onboarding;
          }
        });
      } else {
        updatedOnboardingList = [...onboardingList, currentStep];
      }

      dispatch({
        type: 'DEFINE_ONBOARDING',
        payload: updatedOnboardingList
      });

      localStorage.setItem('onboarding', JSON.stringify(updatedOnboardingList));
    },
    [dispatch, state.onboarding]
  );

  return { toggleOrAddOnboarding };
};

export default useOnboarding;
