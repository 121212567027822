import { ELocale, DomainTranslations } from 'App/i18n/i18n.types';

import Domain from 'commons/utils/domains';

import esAR from './agency-shipment.es-AR.json';
import esMX from './agency-shipment.es-MX.json';
import ptBR from './agency-shipment.pt-BR.json';

const agencyShipmentTranslations: DomainTranslations = {
  name: Domain.AGENCY,
  languages: {
    [ELocale.ARGENTINA]: esAR,
    [ELocale.MEXICO]: esMX,
    [ELocale.BRAZIL]: ptBR
  }
};

export default agencyShipmentTranslations;
