import React, { useReducer, createContext, useContext } from 'react';

import { ProviderProps } from '../types/provider';

import {
  PreferencesState,
  Action,
  initialState,
  preferencesReducer
} from './reducer/preferences';

type PreferencesContextType = {
  state: PreferencesState;
  dispatch: React.Dispatch<Action>;
};

const PreferencesContext = createContext<PreferencesContextType | undefined>(
  undefined
);

const usePreferences = (): PreferencesContextType => {
  const [state, dispatch] = useReducer(preferencesReducer, initialState);

  return { state, dispatch };
};

const usePreferencesContext = (): PreferencesContextType => {
  const context = useContext(PreferencesContext);

  if (!context) {
    throw new Error(
      'usePreferencesContext must be used within a PreferencesProvider'
    );
  }

  return context;
};

const PreferencesProvider: React.FC<ProviderProps> = ({ children }) => {
  const errors = usePreferences();
  return (
    <PreferencesContext.Provider value={errors}>
      {children}
    </PreferencesContext.Provider>
  );
};

export { usePreferencesContext, PreferencesProvider };
