export enum DeliveryOrderCreationType {
  Detached = 'detached',
  Sale = 'sale'
}

export enum DeliveryOrderFreightType {
  Manual = 'manual',
  Custom = 'custom',
  Fallback = 'fallback',
  NativeCorreios = 'native-correios',
  NuvemEnvio = 'nuvem-envio',
  External = 'external'
}

export enum DeliveryOrderDeliveryType {
  Delivery = 'delivery',
  Return = 'return'
}

export enum DeliveryOrderContractType {
  NUVEMENVIO = 'NE',
  COUNTER = 'Balcao',
  OWN = 'Proprio',
  DROPOFF = 'DROPOFF',
  COLLECT = 'COLLECT'
}

export type ReportType = 'invoice' | 'report' | 'receipt';

export enum NuvemEnvioCategory {
  COLLECT = 'COLLECT',
  GOPLUS = 'DROPOFF'
}

export const ArgentinaProvinces = [
  { code: 'B', name: 'Buenos Aires' },
  { code: 'K', name: 'Catamarca' },
  { code: 'H', name: 'Chaco' },
  { code: 'U', name: 'Chubut' },
  { code: 'C', name: 'Ciudad Autónoma de Buenos Aires' },
  { code: 'BX', name: 'Gran Buenos Aires' },
  { code: 'X', name: 'Córdoba' },
  { code: 'W', name: 'Corrientes' },
  { code: 'E', name: 'Entre Ríos' },
  { code: 'P', name: 'Formosa' },
  { code: 'Y', name: 'Jujuy' },
  { code: 'L', name: 'La Pampa' },
  { code: 'F', name: 'La Rioja' },
  { code: 'M', name: 'Mendoza' },
  { code: 'N', name: 'Misiones' },
  { code: 'Q', name: 'Neuquén' },
  { code: 'R', name: 'Río Negro' },
  { code: 'A', name: 'Salta' },
  { code: 'J', name: 'San Juan' },
  { code: 'D', name: 'San Luis' },
  { code: 'Z', name: 'Santa Cruz' },
  { code: 'S', name: 'Santa Fe' },
  { code: 'G', name: 'Santiago del Estero' },
  { code: 'V', name: 'Tierra del Fuego' },
  { code: 'T', name: 'Tucumán' }
];
