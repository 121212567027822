import { ErrorType } from 'commons/utils/errors/error-type';

export type PreferencesState = {
  error: null | ErrorType.MISSING_FISCAL_INFORMATION | string;
};

export type Action =
  | {
      type: 'SET_ERROR';
      payload: null | ErrorType.MISSING_FISCAL_INFORMATION | string;
    }
  | {
      type: 'CLEAR_ERROR';
    };

const initialState: PreferencesState = {
  error: null
};

const preferencesReducer = (
  state: PreferencesState,
  action: Action
): PreferencesState => {
  switch (action.type) {
    case 'SET_ERROR':
      return { ...state, error: action.payload };

    case 'CLEAR_ERROR':
      return { ...state, error: null };

    default:
      return state;
  }
};

export { initialState, preferencesReducer };
