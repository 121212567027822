import { ELocale, DomainTranslations } from 'App/i18n/i18n.types';

import Domain from 'commons/utils/domains';

import esAR from './single-shipment.es-AR.json';
import esMX from './single-shipment.es-MX.json';
import ptBR from './single-shipment.pt-BR.json';

const singleShipmentTranslations: DomainTranslations = {
  name: Domain.SINGLE_SHIPMENT,
  languages: {
    [ELocale.ARGENTINA]: esAR,
    [ELocale.MEXICO]: esMX,
    [ELocale.BRAZIL]: ptBR
  }
};

export default singleShipmentTranslations;
